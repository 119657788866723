// Header.js
import React from 'react';
import '../Style/Header.css';
import logo from '../assets/logo/logo_1.png';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div>
        <header className="header bg-main-color  px-2 py-4 md:px-12 md:py-2 flex justify-between" id="header">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Startup Aquarium Logo" className='w-24 md:w-48' />
          </Link>
        </div>
        <div className='flex justify-between md:space-x-10 space-x-2'>
          <nav>
            <ul>
              <li className='text-white text-base md:text-xl md:mt-2'><Link to="/blogs"><span>Blogs</span></Link></li>
            </ul>
          </nav>
          <Link to="/signup">
            <button className="bg-second-color hover:bg-white text-xs md:text-xl p-2 rounded-lg mr-4">Join Our WaitList</button>
          </Link>
        </div>
      </header>
      <hr className='opacity-40 text-white border-t'/>
    </div>
    
  );
};

export default Header;

