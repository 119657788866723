import React from 'react';
import '../Style/Footer.css';
import icon1 from '../assets/icons/linkedin.png';
import icon2 from '../assets/icons/communication.png';
import icon3 from '../assets/icons/instagram.png';
import icon4 from '../assets/icons/twitter.png';
import { Link } from 'react-router-dom';


const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="footer bg-third-color px-8 py-16 md:px-24">
    <div className="md:flex md:flex-wrap md:justify-between">
      <div className="">
        <p className='p_1 text-3xl md:text-5xl'>Ready to dive into the world of startups?</p>
        <p className='p_2'>Follow us on social media for tips, resources, and support on your entrepreneurial journey.</p>
      </div>
      <div className="contact_us_block space-y-4">
        <div>
          <Link to="/signup">
            <button className="contact-btn rounded-md hover:bg-white " onClick={scrollToTop}>Contact Us</button>
          </Link>
        </div>
        <div>
          <a href="mailto:Startupsaquarium@gmail.com" className='mail_link'>Startupsaquarium@gmail.com</a>
        </div>
        <div className="social-icons">
          <a href="https://www.linkedin.com/in/startups-aquarium-911308301/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
            <img src={icon1} alt="social media icon" className="" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61558609136339">
            <img src={icon2} alt="social media icon" className="" />
          </a>
          <a href="https://www.instagram.com/startupsaquarium/">
            <img src={icon3} alt="social media icon" className="" />
          </a>
          <a href="https://x.com/startupaquarium">
            <img src={icon4} alt="social media icon" className="" />
          </a>
        </div>
      </div>
    </div>
    <div className='border-t border-gray-100 mt-12 pt-8 text-xs md:text-xl'>
      <p>Copyright &copy; 2024 Startups Aquarium. All rights reserved.</p>
    </div>
    </footer>
  );
};

export default Footer;