import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Blog from '../components/MediumBlogs';

const BlogDetail = () => {
  const { id } = useParams();
  const decodedId = decodeURIComponent(id);
  const [post, setPost] = useState(null);


  useEffect(() => {
    axios
      .get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@startupsaquarium')
      .then((res) => {
        const selectedPost = res.data.items.find((item) => item.guid === decodedId);
        setPost(selectedPost);
        console.log(selectedPost)
      })
      .catch((error) => {
        console.error('Error fetching blog post:', error);
      });
  }, [decodedId]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div>
    <Header/>
    <div className='mx-4 md:mx-10 md:w-3/5 md:mx-auto py-2 md:py-12 border-b border-gray-200'>
      <h2 className='text-3xl md:text-5xl font-bold text-left pb-4 md:pb-12'>{post.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: post.content }} className='border-t border-gray-500 py-6 text-left BlogContent'/>
      <h2 class="title-font font-medium text-gray-400 text-left">
        {post.categories.map((cate) => (
            <p className='text-lg mr-3 bg-blue-600 p-3 text-white inline-block mb-1 rounded-lg'>{cate}</p>
        ))}
      </h2>
    </div>
    <h2 className='text-5xl font-bold mt-5 text-left px-10'>Latest Blogs</h2>
    <Blog/>
    <Footer/>
    </div>
    
  );
};

export default BlogDetail;
