// src/pages/Signup.js
import React from 'react';
import './Signup.css';
import Footer from '../components/Footer';
import logo from '../assets/logo/logo_2.png';
import WaitingListForm from '../components/WaitingListForm';
import { Link } from 'react-router-dom';

const Signup = () => {
  return (
    <div className="signup-page w-full">
    <div>
        <header className="header bg-main-color  px-2 py-4 md:px-12 md:py-2 flex justify-between" id="header">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Startup Aquarium Logo" className='w-24 md:w-48' />
          </Link>
        </div>
        <div className='flex justify-between md:space-x-10 space-x-2'>
          <nav>
            <ul>
              <li className='text-white text-base md:text-xl md:mt-2'><Link to="/blogs"><span>Blogs</span></Link></li>
            </ul>
          </nav>
        </div>
      </header>
      <hr className='opacity-40 text-white border-t'/>
    </div>
      
      <div className="signup-content w-full md:flex md:justify-between ">
        <div className="signup-left w-full md:w-1/2">
          <h2 className='text-3xl leading-5 md:text-5xl md:leading-10'>Join our community to</h2>
          <h2 className='text-3xl leading-5 md:text-5xl md:leading-10'>access exclusive </h2>
          <h2 className='text-3xl leading-5 md:text-5xl md:leading-10'>content & features.</h2>
          <ul>
            <li>Display Your Startup’s Evolution.</li>
            <li>Forge Investors Connection.</li>
            <li>Secure Necessary Resources.</li>
            <li>Expand Your Network.</li>
          </ul>
        </div>
        <div className="signup-right bg-main-color w-full md:w-1/2">
          <h2 className='leading-8 text-3xl md:text-5xl md:leading-12'>Join Startups Aquarium Today!</h2>
          <WaitingListForm/>
        </div>
      </div>
      
      <Footer/>
    </div>
  );
};

export default Signup;