import React from 'react';
import '../Style/CallToAction.css';
import hero from '../assets/illustration/call_to_action.png';
import { Link } from 'react-router-dom';
import bubble from  '../assets/illustration/bubble_3.png';

const CallToAction = () => {
  return (
    <div className='relative py-2 md:py-2'>
      <div className='absolute w-24 right-8 bottom-6 md:right-32 md:bottom-20 md:w-56 opacity-25'>
        <img src={bubble} alt="startup aquarium bubbles"/>
      </div>
      <section className="call-to-action text-center text-white bg-main-color mx-8 md:mx-32 my-4 md:my-16 rounded-lg md:pb-24 pb-8">
          <div className='text-center pt-4 md:pt-16 p-4'>
            <h2 className='text-xl md:text-5xl my-4 md:my-10 md:leading-10 font-bold'>Don't navigate the startup <br/> seas alone.</h2>
            <Link to="/signup">
              <button className="bg-second-color hover:bg-white  rounded-lg md:mt-5 mb-1 md:mb-2 font-bold">Join Startups Aquarium today</button>
            </Link>
          </div>
          <div className='call_to_action_image mx-auto hidden md:block md:absolute md:bottom-10 bottom-2 inset-x-0 w-full'>
            <img src={hero} alt="call to action"/>
          </div>
        </section>
    </div>
    
  );
};

export default CallToAction;