// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// Optionally import other Firebase services you plan to use

const firebaseConfig = {
    apiKey: "AIzaSyCUTQPD97s8vSqASsFq8MpXJsjVfBINq2w",
    authDomain: "startupaquarium.firebaseapp.com",
    projectId: "startupaquarium",
    storageBucket: "startupaquarium.appspot.com",
    messagingSenderId: "722526029324",
    appId: "1:722526029324:web:259d5a0cdc698d7ca49029"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
// Optionally initialize other Firebase services

export { app, db, auth };
