// PropelSection.js
import React from 'react';
import '../Style/PropelSection.css';

import hero from '../assets/illustration/hero_2.png';

import icon1 from '../assets/icons/user.png';
import icon2 from '../assets/icons/connection.png';
import icon3 from '../assets/icons/crowd-of-users.png';
import icon4 from '../assets/icons/structure.png';
import icon5 from '../assets/icons/requirement.png';
import icon6 from '../assets/icons/news.png';

const features = [
  { icon: icon1, title: 'Profile', description: "Showcase your ideas, products, and team members with detailed startup profiles." },
  { icon: icon2, title: 'Connect With People', description: "Connect with mentors, investors, and entrepreneurs using our networking features." },
  { icon: icon3, title: 'Community', description: "Discover relevant groups, forums, and discussions with our search functionality." },
  { icon: icon4, title: 'Government Sites', description: 'Access to government websites, resources, and programs supporting startups.' },
  { icon: icon5, title: 'Raise Requirement', description: 'Feature to post and match requirements for funding, talent, partnerships, etc.' },
  { icon: icon6, title: 'News/Blogs', description: 'Curated news, articles, and insights relevant to startups and entrepreneurship.' }
];

const PropelSection = () => {
  return (
    <section className="pt-2 bg-white">
      
      <section class="text-gray-600 body-font md:pt-2 px-4 md:px-8">
        <div class="container md:flex md:flex-wrap md:px-5 py-2 md:py-2 mx-auto items-center">
          <div class="md:w-1/2 md:py-8 mb-10 md:mb-0 pb-10">
            <div class="md:mb-10 px-4 propel_hero_section">
              <div class="rounded-lg overflow-hidden -ml-20 h-80">
                <img alt="content" class="object-cover object-center h-full " src={hero}/>
              </div>
              <h2 class="title-font text-3xl leading-8 md:text-4xl md:leading-10 font-medium text-gray-900 mt-6 mb-3 text-left">Propel your startup from a brilliant idea to a thriving enterprise</h2>
              <p className='text-left text-lg md:text-lg'>
                “Startups Aquarium” is a platform designed to support startups at every stage of their journey.
              </p>
              <p class="leading-relaxed text-lg md:text-lg text-left">The platform aims to create a vibrant ecosystem where startups can showcase their ideas, connect with mentors, investors, and other entrepreneurs, access resources, and grow their businesses.</p>
            </div>
          </div>
          <div class="md:w-1/2 md:pl-12">
              <section class="text-gray-600 body-font">
              <div class="container px-2 md:px-5 md:py-6 mx-auto">
                <div class="flex flex-wrap -m-4">
                {features.map((feature, index) => (
                  <div class="w-full md:w-1/2 text-left p-1 md:p-2">
                    <div class="p-6 rounded-lg border border-gray-200 flex md:block">
                      <div class="w-20 h-20 md:w-8 md:h-8 inline-flex items-center justify-center mb-4 mr-4">
                        <img src={feature.icon} alt={feature.title} className="" />
                      </div>
                      <div>
                        <h2 class="text-base text-gray-900 font-bold title-font mb-2 ">{feature.title}</h2>
                        <p class="leading-snug text-sm">{feature.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </section>
  );
};

export default PropelSection;