// FeaturesSection.js
import React from 'react';
import '../Style/FeaturesSection.css';
import icon1 from '../assets/mp4/view.mp4';
import icon2 from '../assets/mp4/global-connection.mp4';
import icon3 from '../assets/mp4/resource.mp4';
import icon4 from '../assets/mp4/agreement.mp4';


const features = [
  { icon: icon1, title: 'Visibility', description: "Get exposure to a wide audience and potential investors." },
  { icon: icon2, title: 'Networking', description: "Connect with mentors, investors, and other entrepreneurs." },
  { icon: icon3, title: 'Resources', description: "Access grants, funding opportunities, and industry news." },
  { icon: icon4, title: 'Collaboration', description: "Collaborate with other startups to share knowledge and ideas." }
];

const FeaturesSection = () => {
  return (

      <section className="features py-4 px-4 md:py-6 md:px-8 bg-gray-100">
        <div className="features-flex md:p-4 flex flex-wrap justify-between items-start rounded-md">
          {features.map((feature, index) => (
            <div key={index} className="feacture_items text-center w-full md:w-1/4 p-2">
              <div className='border border-gray-200 rounded-lg p-6 md:p-3 flex flex-wrap items-center bg-white h-28 md:h-36 w-full'>
                <div className='w-10'>
                  <video class="h-6 md:h-12" autoplay loop muted playsinline>
                      <source src={feature.icon} type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
                </div>
                <div className='feacture_title_main pr-2 pl-6 md:pl-4 border-red-500 text-left'>
                  <h3 className="text-base md:text-xl text-black font-bold">{feature.title}</h3>
                  <p className="feature_description text-xs md:text-sm font-normal">{feature.description}</p>
                </div>
              </div>
              
            </div>
          ))}
        </div>
      </section>
    
  );
};

export default FeaturesSection;