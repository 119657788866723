import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FeaturesComparison from '../components/FeaturesComparison';


const Feature = () => {
  return (
    <>
      <Header />
      <FeaturesComparison/>
      <Footer />
    </>
  );
};

export default Feature;