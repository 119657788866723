// HeroSection.js
import React from 'react';
import '../Style/HeroSection.css';
import { Link } from 'react-router-dom';
import bubble_l from '../assets/illustration/bubbles_l.png';
import bubble_r from '../assets/illustration/bubbles_r.png';

const HeroSection = () => {
  return (
    <section className="hero bg-main-color relative">
      <div className='hidden md:block'>
        <p className='mx-auto font-bold text-2xl md:text-5xl'>Welcome to Startups Aquarium</p>
        <p className='mx-auto font-bold text-3xl md:text-5xl'>where innovation meets opportunity,</p>
        <p className='mx-auto font-bold text-3xl md:text-5xl'>and dreams take flight.</p>
        <h1 className='text-second-color font-bold mb-4 mt-4 text-xl md:text-3xl'>The All In One Platform For Startups To Thrive. </h1>
        
        <p className='mx-auto text-base leading-8 md:text-xl mt-4 max-w-4xl'>At Startups Aquarium, we believe that every great idea deserves a chance to be heard, and every aspiring entrepreneur deserves a platform to showcase their potential.</p>
        <p className='mx-auto text-base leading-8 md:text-xl max-w-4xl'>Join StartupsAquarium as we revolutionize the way startups connect collaborate and succeed in the digital age.</p>
      </div>

      <div className='md:hidden'>
        <h1 className='text-second-color font-bold mb-2 mt-2 text-lg'>The Platform for Startup Success.</h1>
        <p className='font-bold text-xl'>Welcome to Startups Aquarium</p>
        <p className='mx-auto font-bold text-xl'>Innovation meets opportunity, and dreams take flight</p>
        
        <p className='mx-auto text-base leading-6 md:text-xl mt-4'>"At Startups Aquarium, we believe every idea deserves to be heard and every entrepreneur deserves a platform to shine."</p>
        <p className='mx-auto text-base leading-6 md:text-xl'>"Join StartupsAquarium to revolutionize how startups connect, collaborate, and succeed in the digital age."</p>
      </div>
      
      <Link to="/signup">
        <button className="text-black bg-second-color hover:bg-white rounded-lg my-8 py-4 px-8 cursor-pointer">Showcase Your Idea With Us</button>
      </Link>

      <div>
        <div className='absolute bottom-0 left-0 opacity-25'>
          <img src={bubble_l} alt="" srcset="" className='w-1/2 md:w-full'/>
        </div>
        <div className='absolute top-0 right-0 opacity-25'>
          <img src={bubble_r} alt="" srcset="" className=''/>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
