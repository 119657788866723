import React from 'react';

import img1 from '../assets/illustration/preview-1.webp';
import img2 from '../assets/illustration/preview-2.webp';
import img3 from '../assets/illustration/preview-3.webp';

const DownloadApp = () => {
  return (
    <section className="bg-gray-100">
        <div className="md:mx-10 my-10 bg-white pt-10  rounded-2xl overflow-hidden">
            <div className='px-8 md:px-48'>
                <h2 className='text-3xl md:text-5xl font-bold tracking-tight'>Join Largest Community of founder's and investors</h2>
                <p className='text-base md:text-lg my-4'>Revolutionize startup connections with StartupsAquarium. Join now!</p>
                <div className='relative'>
                    <div className='md:flex md:space-x-5 bg-white mt-8 h-80 overflow-y-hidden'>
                        <div className='w-full md:w-1/3 shadow-2xl '><img src={img1} alt=""/></div>
                        <div className='w-full md:w-1/3 shadow-2xl '><img src={img2} alt=""/></div>
                        <div className='w-full md:w-1/3 shadow-2xl '><img src={img3} alt=""/></div>
                    </div>
                    <div className='h-80 bg-black absolute top-0'>
                    </div>
                </div>
                
            </div>
            <div className='bg-second-color py-8'>
                <h3 className='text-3xl font-bold'>Comming soon ...</h3>
                <p className='text-lg my-4'>Join the community! Scan the QR code with your phone camera to download.</p>
            </div>
        </div>
        
    </section>
  );
};

export default DownloadApp;