import React from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import FeaturesSection from '../components/FeaturesSection';
import OneStopShopSection from '../components/OneStopShopSection';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import DownloadApp from '../components/Downloandapp';
import TwoSection from '../components/TwoSection';


const Showcase = () => {
  return (
    <>
      <Header/>
      <HeroSection/>
      <FeaturesSection/>
      <OneStopShopSection/>
      <DownloadApp/>
      <TwoSection/>
      <CallToAction/>
      <Footer/>
    </>
  );
};

export default Showcase;