// src/pages/ThankYou.js
import React from 'react';
import './ThankYou.css';
import Footer from '../components/Footer';
import logo from '../assets/logo/logo_1.png';
import hero from '../assets/illustration/thankyou.png';
import { Link } from 'react-router-dom';

const ThankYou = () => {
  return (
    <div className="thankyou-page">
    <div>
        <header className="header bg-main-color  px-2 py-4 md:px-12 md:py-2 flex justify-between" id="header">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Startup Aquarium Logo" className='w-24 md:w-48' />
          </Link>
        </div>
        <div className='flex justify-between md:space-x-10 space-x-2'>
          <nav>
            <ul>
              <li className='text-white text-base md:text-xl md:mt-2'><Link to="/blogs"><span>Blogs</span></Link></li>
            </ul>
          </nav>
        </div>
      </header>
      <hr className='opacity-40 text-white border-t'/>
    </div>
      <section class="text-white body-font px-12 bg-main-color ">
        <div class="container mx-auto flex px-5 py-5 md:flex-row flex-col items-center">
          <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img class="object-cover object-center rounded" alt="hero" src={hero}/>
          </div>
          <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <div className="thankyou-message">
              <h2>Thanks for Joining StartupsAquarium</h2>
              <p>We’re delighted to welcome you to our community of innovators and entrepreneurs. Stay connected for updates on our platform’s launch. Exciting opportunities await!</p>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default ThankYou;