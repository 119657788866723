import React from 'react';

import img1 from '../assets/illustration/nitin.jpg';
import gif1 from '../assets/mp4/IMG.GIF'
import PropelSection from './PropelSection';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  return (
    <section class="text-gray-600 body-font">
        
        <div class="container md:px-8 py-8 mx-auto md:flex md:flex-wrap">
            <div className='md:w-1/2 text-left p-8 md:p-10 bg-main-color text-white'>
                <h2 className='md:text-4xl font-bold py-10'>We’re Startups Aquarium.</h2>
                <p className='md:text-lg mb-4 '>
                Startups Aquarium was founded with a vision to create a universal hub for startup innovation and success. We’re an independent, digital platform that connects startups and investors to foster growth and inspire collaboration. We’re free to join, open to everyone, and built on transparency.
                </p>
                <p className='md:text-lg pb-10'>
                Startups Aquarium helps entrepreneurs launch and scale their businesses with confidence. By providing resources, mentorship, and networking opportunities, we empower startups to build trust, grow, and improve what they offer. The more startups engage with our platform to share their journeys and successes, the richer the insights we offer, and the more opportunities they have to secure funding and achieve their dreams.
                </p>
            </div>
            <div className='md:w-1/2 bg-gray-200 p-8 md:p-16'>
                <p className='pt-10 font-sm font-bold'>Startups:</p>
                <p className='text-xl font-bold text-black pb-10'>Share your experiences</p>
                <div className='w-60 py-8 px-4 mx-auto'>
                    <img src={gif1} className='w-full' alt="startup's aquarium"/>
                </div>
                <p className='pt-12 font-sm font-bold'>Investors:</p>
                <p className='text-xl  font-bold text-black'>Create better opportunities</p>
            </div>
        </div>
        <PropelSection/>
        <div class="container md:px-8 pt-4 mx-auto md:flex md:flex-wrap">
            <div className='md:w-1/2 text-left'>
                <img src={img1} alt="startup's aquarium"/>
            </div>
            <div className='md:w-1/2 bg-second-color p-8 md:p-16 text-left'>
                <p className='text-2xl font-bold text-black pb-10'>“Our vision is to be the cornerstone of startup success, enabling entrepreneurs to overcome challenges, innovate boldly, and transform industries. By connecting startups with the right investors, mentors, and resources, we empower them to scale new heights. At Startup Aquarium, we believe in fostering a collaborative ecosystem where every startup can thrive and make a meaningful impact.”</p>
                
                <p className='pt-10 font-sm font-bold text-black' >Nitin Bansal</p>
                <p className='font-lg  text-black'>Founder, StartupAquarium</p>
            </div>
        </div>
        
        <div class="container md:px-8 mx-auto md:flex md:flex-wrap">
            <div className='md:w-1/2 text-left p-8 md:p-18'>
                <h2 className='md:text-4xl font-bold text-black py-10'>We’re a passionate bunch of people from all around the world</h2>
            </div>
            <div className='md:w-1/2 bg-gray-200 p-8 md:p-16 text-left'>
                <p className='font-lg font-bold text-black pb-10'>Join the Startup Aquarium community today! 🚀 Unlock a world of opportunities with exclusive access to resources, mentorship, and networking events tailored to help your startup thrive. Don’t miss out on this chance to be part of an innovative ecosystem designed to fuel your entrepreneurial journey.</p>
                <Link to="/signup">
                    <p class="text-white inline-flex items-center border border-white rounded-full py-2 px-6 bg-main-color font-bold">Join Startup Aquarium</p>
                </Link>
                
            </div>
        </div>
    </section>
  );
};

export default AboutPage;