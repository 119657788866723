import React from 'react';
import '../Style/OneStopShopSection.css';
import hero from '../assets/illustration/onestop.jpg'
import { Link } from 'react-router-dom';

const OneStopShopSection = () => {
  return (
    <div className='md:relative bg-gray-100'>
      <section className="one-stop-shop bg-second-color px-8 py-5 md:px-16 md:py-10 md:flex md:mr-32">
        
        <div className="content md:w-2/3  mt-4 text-left">
          <h5 className='text-xl md:text-3xl'>One Stop Shop</h5>
          <h2 className='text-2xl md:text-5xl font-bold mb-1'>Launch your startup from </h2>
          <h2 className='text-2xl md:text-5xl font-bold mb-1'>idea to success</h2>
          <ul className='mt-4 md:mt-8 mb-4 pl-4 list-disc'>
            <li className='text-base leading-4 md:text-lg md:leading-8 mb-3'><b>Global Exposure:</b> Showcase your innovative ideas & reach <br className='hidden md:block'/> a worldwide audience.</li>
            <li className='text-base leading-4 md:text-lg md:leading-8 mb-3'><b>Connect & Network: </b>Interact with potential <br className='hidden md:block'/>investors, customers, and collaborators.</li>
            <li className='text-base leading-4 md:text-lg md:leading-8 mb-3'><b>Attract Funding:</b> Get your startup discovered by <br className='hidden md:block'/> VCs and angel investors.</li>
            <li className='text-base leading-4 md:text-lg md:leading-8 mb-3'><b>Build Your Brand:</b> Gain instant credibility and establish <br className='hidden md:block'/> your presence in the market.</li>
            <li className='text-base leading-4 md:text-lg  md:leading-8 mb-3'><b>Gather Feedback:</b> Refine your product or service with <br className='hidden md:block'/> valuable insights from visitors.</li>
          </ul>
          <Link to="/signup">
            <button className="bg-main-color hover:bg-white hover:text-black text-white px-8 py-2 rounded-lg">Join Us</button>
          </Link>
        </div>
        <div className="one_stop_image md:w-96 mt-4 md:ml-8 md:absolute md:right-16 md:top-20">
          <img src={hero} alt="Launch your startup" className='w-full'/>
        </div>
        
      </section>
    </div>
    
  );
};

export default OneStopShopSection;