// HeroSection.js
import React from 'react';
import '../Style/HeroSection.css';


const HeadBlog = () => {
  return (
    <section className="hero bg-main-color relative">
      <div className=' md:py-24'>
        <p className='mx-auto font-bold text-2xl md:text-5xl'>NEWS AND BLOGS</p>
        <h1 className='text-white mb-4 mt-4 text-base md:text-xl'>We're thrilled to have you here. Dive into a wealth of knowledge, insights, and stories from the world of startups. Whether you're a budding entrepreneur, a seasoned founder, or simply interested in the startup ecosystem, our blog is designed to offer valuable content to inspire and guide you on your entrepreneurial journey.</h1>
      </div>
    </section>
  );
};

export default HeadBlog;
