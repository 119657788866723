import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Blog from '../components/MediumBlogs';
import HeadBlog from '../components/HeadBlog';


const Blogs = () => {
  return (
    <>
      <Header />
      <HeadBlog/>
      <Blog/>
      <Footer />
    </>
  );
};

export default Blogs;