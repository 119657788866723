import React from 'react';
import { Link } from 'react-router-dom';

const TwoSection = () => {
  return (
    <section class="text-gray-600 body-font">
  <div class="container py-16 mx-auto items-center md:relative">
    <div class="w-full md:pr-12 p-6 md:px-20 md:py-28 md:border-r md:border-b-0 md:mb-10 md:mb-0 pb-10 border-b border-gray-200 bg-main-color text-white text-left">
        <div className='md:w-1/2'>
            <h1 class="sm:text-3xl text-5xl font-bold title-font mb-4 ">Be heard</h1>
            <p class="leading-relaxed text-base mb-4">Startup Aquarium is a platform designed to give a voice to startups and their ecosystems. Share your journey, experiences, and feedback to help us build a better environment for innovation and growth.</p>
            <Link to="/about">
              <p class="text-white inline-flex items-center mt-4 border border-white rounded-full py-4 px-8">Learn More</p>
            </Link>
        </div>
    </div>
    <div class="w-full md:w-1/3 md:pr-12 p-6 md:px-10 md:py-20 md:border-r md:border-b-0 md:mb-10 md:mb-0 pb-10 border-b border-gray-200 bg-second-color text-black text-left rounded-3xl md:absolute md:top-0 md:right-24">
      <h1 class="sm:text-3xl text-5xl font-bold title-font mb-4 ">How Startups Aquarium Stands Out</h1>
      <p class="leading-relaxed text-base mb-4">We’re setting new standards in the startup ecosystem. Compare our features with other platforms and see why Startups Aquarium is the best choice for founders and investors.</p>
      
      <Link to="/feature">
        <p class="text-white inline-flex items-center mt-4 rounded-full py-4 px-8 bg-black text-white" >Feature Comparison</p>
      </Link>
    </div>
  </div>
</section>
  );
};

export default TwoSection;