// src/components/WaitingListForm.js
import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import '../Style/WaitingListForm.css';

const WaitingListForm = () => {
  const [formData, setFormData] = useState({
    experience: '',
    role: '',
    hearAboutUs: '',
    linkedin: '',
    phoneNumber: '',
    companyName: '',
    website: '',
    lookingFor: '',
    name: '',
    email: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'waiting_list'), {
        experience: formData.experience,
        role: formData.role,
        hearAboutUs: formData.hearAboutUs,
        linkedin: formData.linkedin,
        phoneNumber: formData.phoneNumber,
        companyName: formData.companyName,
        website: formData.website,
        lookingFor: formData.lookingFor,
        name: formData.name,
        email: formData.email
      });
      alert('Entry added to waiting list');
      setFormData({
        experience: '',
        role: '',
        hearAboutUs: '',
        linkedin: '',
        phoneNumber: '',
        companyName: '',
        website: '',
        lookingFor: '',
        name: '',
        email: ''
      });
      window.location.href = '/thankyou'; 
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('Error adding entry to waiting list');
    }
  };

  return (
    <form onSubmit={handleSubmit} className='waiting_form'>
        <div>
            <label>Your Name:</label>
            <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            />
        </div>
        <div>
            <label>Your Email:</label>
            <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            />
        </div>
        <div>
            <label>Phone Number:</label>
            <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            />
        </div>
        <div>
            <label>Startup Name / Company Name:</label>
            <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            required
            />
        </div>
        <div>
            <label>Brief Description of Your Experience:</label>
            <textarea
            name="experience"
            value={formData.experience}
            onChange={handleChange}
            required
            />
        </div>
        <div>
            <label>Choose Your Role:</label>
            <select
                name="role"
                value={formData.role}
                onChange={handleChange}
                required
                >
                <option value="" disabled>Select your role</option>
                <option value="Founder">Founder</option>
                <option value="Investor">Investor</option>
                <option value="Mentor">Mentor</option>
                <option value="Job Seeker">Job Seeker</option>
            </select>
        </div>
        
        <div>
            <label>LinkedIn Profile URL:</label>
            <input
            type="url"
            name="linkedin"
            value={formData.linkedin}
            onChange={handleChange}
            required
            />
        </div>
        <div>
            <label>Website (if any):</label>
            <input
            type="url"
            name="website"
            value={formData.website}
            onChange={handleChange}
            />
        </div>
        <div>
            <label>What are you looking for? (e.g., funding, mentorship, partnerships):</label>
            <input
            type="text"
            name="lookingFor"
            value={formData.lookingFor}
            onChange={handleChange}
            required
            />
        </div>
        <div>
            <label>How did you hear about us?</label>
            <select
                name="hearAboutUs"
                value={formData.hearAboutUs}
                onChange={handleChange}
                required
                >
                <option value="" disabled>Choose</option>
                <option value="Linkedin">Linkedin</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Twitter">Twitter</option>
            </select>
        </div>
        <button type="submit" >Submit</button>
    </form>
  );
};

export default WaitingListForm;
