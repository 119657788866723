import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Showcase from './pages/Showcase';
import Signup from './pages/Signup';
import ThankYou from './pages/ThankYou';
import Blogs from './pages/blogs';
import BlogDetail from './pages/BlogDetail';
import './App.css';
import About from './pages/About';
import Feature from './pages/Feature';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Showcase />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/post/:id" element={<BlogDetail />} />
          <Route path="/about" element={<About/>} />
          <Route path="/feature" element={<Feature/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;