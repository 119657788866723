import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import blog_image_not_found from "../assets/illustration/blog_image_not_found.jpg";
import "../App.css";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const getPostData = () => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@startupsaquarium"
      )
      .then((res) => {
        const searchString = "\n\u003Cfigure\u003E\u003Cimg";
        const appendString = `\n\u003Cfigure\u003E\u003Cimg alt="" src="${blog_image_not_found}"\u003E\u003C/figure\u003E\u003Cp\u003E`;
        for (let data of res.data.items) {
          if (!data.description.includes(searchString)) {
            data.description = `${appendString}${data.description}`;
          }
        }
        setPosts(res.data.items);
        console.log(res.data);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  };
  useEffect(() => {
    getPostData();
  }, []);
  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            {posts.map((post) => (
              <div className="p-4 md:w-1/2 lg:w-1/3" key={post.guid}>
                <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden text-left">
                  <div className="p-6">
                    <h1 className="title-font text-lg font-medium text-gray-900 mb-3 prose prose-sm line-clamp-2 h-16">
                      {post.title}
                    </h1>
                    <p
                      className="leading-relaxed mb-3 prose prose-sm line-clamp-3"
                      dangerouslySetInnerHTML={{ __html: post.description }}
                    ></p>
                    <div className="flex items-center flex-wrap ">
                      <Link to={`/post/${encodeURIComponent(post.guid)}`}>
                        <p className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                          Learn More
                          <svg
                            className="w-4 h-4 ml-2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14"></path>
                            <path d="M12 5l7 7-7 7"></path>
                          </svg>
                        </p>
                      </Link>
                      <h2 className="text-xs title-font relative -bottom-2 font-medium text-gray-400 mb-1">
                        {post.categories.map((cate, index) =>
                          index < 4 ? (
                            <p
                              key={index + 1}
                              className="mr-1 bg-blue-600 p-2 px-2 text-white inline-block mb-1 rounded-lg"
                            >
                              {cate}
                            </p>
                          ) : (
                            <></>
                          )
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};
export default Blog;

// <p dangerouslySetInnerHTML={{ __html: post.content }} />
