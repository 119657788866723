import React from 'react';
import '../Style/FeaturesComparison.css';
import logo from '../assets/logo/logo_2.png';

const features = [
  { title: 'Focus', description: "Specifically tailored to support startups by providing a dedicated platform for showcasing ideas, connecting with mentors and investors, accessing resources, and building a supportive community." },
  { title: 'Audience', description: "Aspiring entrepreneurs, startup founders, mentors, investors, and industry experts looking to support and engage with startups, access resources, and build connections within the startup ecosystem." },
  { title: 'Content', description: "Startup profiles, discussions, resources, mentorship opportunities, funding information, news, and updates relevant to startups and entrepreneurship." },
  { title: 'Features', description: "Startup profiles, networking features, community forums, mentorship programs, resource hub, funding opportunities, and news and updates tailored to startups." },
  { title: 'Purpose', description: "Supporting startups by providing a platform for showcasing ideas, connecting with mentors and investors, accessing resources, and building a supportive community to foster growth and success." }

];

const FeaturesComparison = () => {
  return (
    <div>
      <section className="features-comparison py-16 px-4 bg-white hidden md:block">
        <div className='text-center pb-16'>
          <h2 className='mx-auto text-4xl lg:text-6xl font-bold'>Features Comparison</h2>
          <h3 className='mx-auto text-2xl lg:text-5xl font-bold'>Startups Aquarium vs Other Platforms</h3>
          <p className='mx-auto text-base lg:text-xl'>Here's a head-to-head comparison between Startups Aquarium and other platforms to help you</p>
          <p className='mx-auto text-lg lg:text-xl'>clearly differentiate between different platforms</p>
        </div>
        
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                <th className="text-center p-4"></th>
                <th className="text-center p-4"><img src={logo} alt='startup aquarium logo' className="mx-auto"/></th>
                <th className="text-center p-4">LinkedIn</th>
                <th className="text-center p-4">Instagram</th>
                <th className="text-center p-4">Facebook</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='features_comparison_first_column p-4 font-bold text-left'>Focus</td>
                <td className='features_comparison_second_column p-4'>Specifically tailored to support startups by providing a dedicated platform for showcasing ideas, connecting with mentors and investors, accessing resources, and building a supportive community.</td>
                <td className='features_comparison_other_column p-4'>Primarily a professional networking platform focused on career development, job searching, and business connections.</td>
                <td className='features_comparison_other_column p-4'>A visual social media platform centered around sharing photos and videos with friends and followers.</td>
                <td className='features_comparison_other_column p-4'>A general social networking platform for connecting with friends, family, and communities, sharing updates, and discovering content.</td>
              </tr>
              <tr className='border-t border-gray-300'>
                <td className='features_comparison_first_column p-4 font-bold text-left'>Audience</td>
                <td className='features_comparison_second_column p-4'>Aspiring entrepreneurs, startup founders, mentors, investors, and industry experts looking to support and engage with startups, access resources, and build connections within the startup ecosystem.</td>
                <td className='features_comparison_other_column p-4'>Professionals, job seekers, recruiters, and businesses looking to network, recruit talent, and grow their professional network.</td>
                <td className='features_comparison_other_column p-4'>Users of all ages interested in sharing visual content, following influencers, and discovering new trends and inspiration.</td>
                <td className='features_comparison_other_column p-4'>A diverse user base including individuals, businesses, organizations, and communities connecting and sharing content with friends and followers.</td>
              </tr>
              <tr className='border-t border-gray-300'>
                <td className='features_comparison_first_column p-4 font-bold text-left'>Content</td>
                <td className='features_comparison_second_column p-4'>Startup profiles, discussions, resources, mentorship opportunities, funding information, news, and updates relevant to startups and entrepreneurship.</td>
                <td className='features_comparison_other_column p-4'>Professional profiles, job postings, industry news, articles, and professional updates.</td>
                <td className='features_comparison_other_column p-4'>Photos, videos, Stories, Reels, IGTV, and captions showcasing personal moments, experiences, and interests.</td>
                <td className='features_comparison_other_column p-4'>Status updates, photos, videos, events, groups, pages, and news feed content from friends, pages, and groups.</td>
              </tr>
              <tr className='border-t border-gray-300'>
                <td className='features_comparison_first_column p-4 font-bold text-left'>Features</td>
                <td className='features_comparison_second_column p-4'>Startup profiles, networking features, community forums, mentorship programs, resource hub, funding opportunities, and news and updates tailored to startups.</td>
                <td className='features_comparison_other_column p-4'>Professional networking, job searching, company pages, groups, messaging, and premium features like LinkedIn Learning and Sales Navigator.</td>
                <td className='features_comparison_other_column p-4'>Filters, hashtags, Explore tab, direct messaging, shopping, IGTV, Reels, and insights for business accounts.</td>
                <td className='features_comparison_other_column p-4'>News feed, groups, events, marketplace, advertising, messaging, fundraisers, and pages for businesses and organizations.</td>
              </tr>
              <tr className='border-t border-gray-300'>
                <td className='features_comparison_first_column p-4 font-bold text-left'>Purpose</td>
                <td className='features_comparison_second_column p-4'>Supporting startups by providing a platform for showcasing ideas, connecting with mentors and investors, accessing resources, and building a supportive community to foster growth and success.</td>
                <td className='features_comparison_other_column p-4'>Facilitating professional connections, career advancement, job opportunities, and business networking.</td>
                <td className='features_comparison_other_column p-4'>Sharing visual content, building personal brands, connecting with followers, and discovering trends and inspiration.</td>
                <td className='features_comparison_other_column p-4'>Connecting with friends and family, staying updated on news and events, joining groups, and sharing content.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section className="py-16 px-4 bg-white md:hidden">
          <div className='text-center pb-8'>
            <h2 className='mx-auto text-4xl lg:text-6xl font-bold'>Features </h2>
            <h3 className='mx-auto text-2xl lg:text-5xl font-bold'>Startups Aquarium</h3>
            <p className='mx-auto text-base'>"Compare Startups Aquarium with other platforms to see the differences clearly."</p>
          </div>
          
          <div className="">
          {features.map((feature, index) => (
            <div key={index} className="w-full p-2 border-red-500">
                <div className='px-2 pb-4 border-b border-gray-300'>
                  <h3 className="text-2xl text-black font-bold text-left">{feature.title}</h3>
                  <p className="text-base font-normal text-left">{feature.description}</p>
                </div>
            </div>
          ))}
          </div>
        </section>
    </div>
    
  );
};

export default FeaturesComparison;
